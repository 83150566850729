<template>
    <div class="box">
        <div class="box-404">
            <div class="title">404 !</div>
            <div class="text">很抱歉，您访问的页面不存在</div>
            <div class="btn">
                <el-button type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.go(-2)
        }
    }
}

</script>

<style lang="less" scoped>
.box {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #253074;

    .box-404 {
        @boxWidth: 800px;
        @boxHeight: 500px;

        width: @boxWidth;
        height: @boxHeight;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: (@boxWidth / -2);
        margin-top: (@boxHeight / -2);
        color: #fff;
        text-align: center;

        .title {
            font-size: 300px;
            font-weight: 900;
        }

        .text {
            font-size: 50px;
        }

        .btn {
            margin-top: 50px;

            .el-button--primary {
                background: #e0e1ef !important;
                border-color: #e0e1ef !important;
                color: #253074 !important;
            }
        }
    }
}
</style>